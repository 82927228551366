import { SET_TASKS, SET_SPINNER, SET_FILTERS } from './../actions/Types'

const initialState = {
    count: 0,
    tasks: [],
    spinner: true,
    filters: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_TASKS:
            return {
                ...state,
                count: action.payload.count,
                tasks : action.payload.tasks
            }
        case SET_SPINNER:
            return {
                ...state,
                spinner: action.payload
            }
        case SET_FILTERS:
            return {
                ...state,
                filters: action.payload
            }
        default: 
            return state
    }
}
