import React, { Component } from "react"
import { Button, Form, Spinner } from "react-bootstrap"
import { FaPlusSquare } from "react-icons/fa"
import { IconContext } from "react-icons"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import TaskDashboardTable from './TaskDashboardTable'
import './css/TaskDashboard.css'
import TaskPagination from './TaskPagination'

class TaskDashboard extends Component {

    constructor(props) {
        super()
    }

    render() {
        return (
            <div>
                <Link to={"task"}>
                    <Button variant="dark" className="mb-1 mt-3 float-right" size="sm" active>
                        <IconContext.Provider value={{ className: "icon-button"}}>
                            <FaPlusSquare />
                        </IconContext.Provider>
                        <span>Add New Task</span>
                    </Button>
                </Link>
                {this.props.spinner ? 
                    <Spinner animation="border" /> :
                    <React.Fragment>
                        <TaskDashboardTable count={this.props.count} tasks={this.props.tasks} />   
                        <TaskPagination />
                    </React.Fragment>
                }    
            </div>
        )
    }
}

const mapStateToProps = state => ({
    tasks: state.TaskDashboardReducer.tasks,
    count: state.TaskDashboardReducer.count,
    spinner: state.TaskDashboardReducer.spinner
})

export default connect(mapStateToProps, {})(TaskDashboard)

