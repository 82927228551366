import UserReducer from '../reducers/UserReducer'
import { SET_USER } from './Types'

export const logout = () => {
    return function(dispatch) {
        localStorage.clear()
        dispatch(setUser(null))

        window.location.href="/"
    }
}

export const setUser = user => dispatch => {
    dispatch({
        type: SET_USER,
        payload: user
    })
}