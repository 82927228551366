import React from "react"
import {Container} from 'react-bootstrap'
import TaskFilter from './TaskFilter'
import TaskDashboard from './TaskDashboard'

export default function TaskList() {

    return (
        <Container>
            <TaskFilter />
            <TaskDashboard />
        </Container>
    )
}