import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { UNREAD_TASK_COUNT_TIMEOUT } from '../utils/Constant'
import ReactNotifications from 'react-browser-notifications'
import Img from './../images/brand-logo.png'

function UnreadCount() {
    const user = useSelector(state => state.UserReducer.user)
    const [unreadCount, setUnreadCount] = useState(0)
    const notificationRef = useRef(null);


    const handleClick = (event) => {
        window.focus()
        notificationRef.current.close(event.target.tag);
    }

    useEffect(() => {
        let timer;
        async function refreshUnreadCount() {
            const response = await axios.get(`/organizations/${user.defaultOrganization.code}/tasks/unread-count?userId=${user.id}`)
            setUnreadCount(response.data)
            timer = setTimeout(refreshUnreadCount, UNREAD_TASK_COUNT_TIMEOUT)
        }
        refreshUnreadCount()

        return () => {
            clearTimeout(timer)
        }
    }, [user.defaultOrganization.code]);

    useEffect(() => {
        let timer;
        async function newTaskNotification() {
            const response = await axios.get(`/organizations/${user.defaultOrganization.code}/tasks/new-task-count?userId=${user.id}&milliseconds=${UNREAD_TASK_COUNT_TIMEOUT}`)
            if (response.data > 0 && notificationRef.current.supported()) {
                notificationRef.current.show();
            }
            timer = setTimeout(newTaskNotification, UNREAD_TASK_COUNT_TIMEOUT)
        }
        timer = setTimeout(newTaskNotification, UNREAD_TASK_COUNT_TIMEOUT)
        return () => {
            clearTimeout(timer)
        }
    }, [user.defaultOrganization.code]);

    return (
        <React.Fragment>
            {unreadCount}
            <ReactNotifications
                onRef={ref => notificationRef.current = ref}
                title={`Hey ${user.name}!`}
                body="You have new tasks assigned"
                icon={Img}
                tag="biztrack-task-notification"
                onClick={event => handleClick(event)}
            />
        </React.Fragment>
    )
}

export default UnreadCount