import React from 'react'
import { Link } from "react-router-dom"
import parser from "html-react-parser"
import { Table, OverlayTrigger } from "react-bootstrap"
import { FaEdit } from "react-icons/fa"
import { IconContext } from "react-icons"
import MultipleTaskDescription from './FullTaskDescription'
import { renderTooltip } from '../../utils/Tooltip'
import { useSelector } from 'react-redux'
import ActionIcon from '../../utils/ActionIcon'

function TaskDashboardTable(props) {
    const singleTaskDescripton = (taskDescription, taskId) => (
        <td className='biz__task-dashboard_table-description'>
            <div className='biz__task-dashboard_single-description'>
                <div>{parser(taskDescription.desc)}</div>
                {ActionIcon('biz__task-dashboard_table-description_icon', taskFilters.status !== "COMPLETE", '15', '#17a2b8', `/task/${taskId}`)}   
            </div>         
        </td>
    )

    const multipleTaskDescription = (task) => <MultipleTaskDescription task={task} status={taskFilters.status} link={`/task/${task.id}`}/>
    const taskFilters = useSelector(state => state.TaskDashboardReducer.filters)
    const user = useSelector(state => state.UserReducer.user)
    const isUnread = (task) => {
        return task.assignee.id === user.id && !task.viewed && ['COMPLETE', 'DEL'].indexOf(task.taskStatus.type) < 0
    }
    return (
        <>
        <Table striped bordered hover size="sm" className="biz__task-dashboard_table taskDashboardTable">
            <thead>
                <tr className="noWrap">
                    <th>Customer Name</th>
                    <th>Type</th>
                    <th>Last Updated</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Assignee</th>
                    {taskFilters.status === "COMPLETE" ? null
                    :
                    <th>Action</th>
                    }
                </tr>
            </thead>
            <tbody>
                {
                props.tasks.length === 0 ?
                    <tr align="center">
                        <td colSpan="7">No task is available</td>
                    </tr> :
                    props.tasks.map((task) => (
                        <tr key={task.id} className={isUnread(task) ? "bold" : ""}>
                            <td className="noWrap">{task.customer.name}</td>
                            <td className="noWrap">{task.businessType.desc}</td>
                            <td className="noWrap">{task.lastUpdatedTime.slice(0, 10)}</td>
                            {task.descriptions.length === 1 ?
                                singleTaskDescripton(task.descriptions[0], task.id) :
                                multipleTaskDescription(task)}
                            <td className="noWrap">{task.taskStatus.description}</td>
                            <td className="noWrap">{task.assignee.name}</td>
                            {taskFilters.status === "COMPLETE" ? null
                            :
                            <td className="noWrap">
                                <Link to={`/task/${task.id}`}><span className="mr-1 table-action-text">Edit</span>
                                    <IconContext.Provider value={{ style: { color: "black", marginBottom: "5px" } }}>
                                        <OverlayTrigger
                                            placement="bottom-start"
                                            overlay={renderTooltip(`edit-icon-tooltip-${task.id}`, "Edit Task")}
                                        >
                                        <FaEdit />
                                        </OverlayTrigger>
                                    </IconContext.Provider>
                                </Link>
                            </td>
                            }
                        </tr>
                    ))       
                }
            </tbody>
        </Table>

        <Table striped bordered hover size="sm" className="biz__task-dashboard_table-sm taskDashboardTable">
        <thead>
            <tr className='biz__task-dashboard_table-sm_header'>
                <th className='biz__task-dashboard_table-sm_col'>
                    <div>Date</div>
                    <div>Name</div>
                    <div>Type</div>
                </th>
                <th className='biz__task-dashboard_table-sm_col'>
                    <div>Status</div>
                    <div>Assignee</div>
                </th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
            {
            props.tasks.length === 0 ?
                <tr align="center">
                    <td colSpan="7">No task is available</td>
                </tr> :
                props.tasks.map((task) => (
                    <tr className='biz__task-dashboard_table-sm_row' key={task.id} className={isUnread(task) ? "bold" : ""}>
                        <td className='biz__task-dashboard_table-sm_data'>
                            <div>{task.lastUpdatedTime.slice(0, 10)}</div>
                            <div>{task.customer.name}</div>
                            <div>{task.businessType.desc}</div>
                        </td>
                        <td className='biz__task-dashboard_table-sm_data'>
                            <div>{task.taskStatus.description}</div>
                            <div>{task.assignee.name}</div>
                        </td>
                        {task.descriptions.length === 1 ?
                            singleTaskDescripton(task.descriptions[0], task.id) :
                            multipleTaskDescription(task)}
                    </tr>
                ))       
            }
        </tbody>
        </Table>
    </>
    )
}

export default TaskDashboardTable