import React from 'react'
import axios from 'axios'

function InvalidateCache() {

    axios.post(`/admin/invalidate-cache`)
         .catch(error => {
            console.log(error)
        })
    return (
        <React.Fragment>
            The cache has been invalidated
        </React.Fragment>

    )
}

export default InvalidateCache
