import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import './css/Login.css'
import TextError from '../model/TextError'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import GlobalErrorMessage from '../model/GlobalErrorMessage'
import jsonwebtoken from 'jsonwebtoken'
import { connect } from 'react-redux'
import { setUser } from '../../actions/UserAction'
import base64 from 'react-native-base64'
import ReCAPTCHA from 'react-google-recaptcha'
   
class Login extends Component {

    constructor(props) {
        super()

        this.state = {
            isGlobalError: false
        }
    }

    render() {
        const recaptchaRef = React.createRef()

        const initialValues =  {
            username: "",
            password: "",
        }
        
        const onSubmit = async (values, {setSubmitting}) => {
            const token = await recaptchaRef.current.executeAsync()
            const payload = {
                ...values,
                password: base64.encode(process.env.BIZTRACK_SECRET + values.password),
                g_token: token
            }
            axios.post("/authenticate", payload)
                .then(response => response.data)
                .then((data) => {
                    this.setState({isGlobalError: false})
                    localStorage.setItem('token', data.jwt)
                    this.props.setUser(jsonwebtoken.decode(data.jwt))
                    window.location.reload();
                })
                .catch(error => {
                    console.log(error)
                    setSubmitting(false)
                    recaptchaRef.current.reset()
                    this.setState({isGlobalError: true})
                })
        }

        const validationSchema = Yup.object({
            username: Yup.string().required("Required"),
            password: Yup.string().required("Required"),
        })

        return (
            <div className="login-container">
                <div className="wrap-form">
                    <div className="form-title">
                        <span className="title">
                            Welcome to BizTrack
                        </span>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting }) => {
                            return (
                                <Form className="login-form">
                                    { this.state.isGlobalError && 
                                            <div className="login-global-error">    
                                                <GlobalErrorMessage message="Wrong Username or Password, please try again!"/>
                                            </div>
                                    }
                                 
                                    <h1 className='login-form_sign-text mb-26'>Sign In</h1>
                                    
                                    <div className="wrap-input mb-26">
                                        <label className="input-label" htmlFor="username">Username</label>
                                        <Field className="input-field" name="username" id="username" />
                                        <ErrorMessage name='username' component={TextError} />
                                    </div>

                                    <div className="wrap-input mb-18">
                                        <label className="input-label" htmlFor="password">Password</label>
                                        <Field className="input-field" type="password" name="password" id="password" autoComplete="on"/>
                                        <ErrorMessage name='password' component={TextError} />
                                    </div>

                                    <div className="login-button-container">
                                        <Button className="login-btn" name="login" disabled={isSubmitting} type="submit">
                                            Login
                                        </Button>
                                        <ReCAPTCHA
                                            sitekey={process.env.BIZTRACK_RECAPTCHA_SITE_KEY}
                                            size="invisible"
                                            ref={recaptchaRef}
                                        />
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.user,
})

export default connect(mapStateToProps, { setUser }) (Login)