import React, { Component } from 'react'
import { Card, Form, Row, Col, Button } from 'react-bootstrap'
import axios from 'axios'
import { connect } from 'react-redux'
import { FaCircleNotch } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { IconContext } from "react-icons"
import { setTasks, setSpinner, setFilters } from '../../actions/TaskDashboardAction'
import { setUser } from '../../actions/UserAction'
import { TASK_PAGE_SIZE } from '../../utils/Constant'
import { loadTasks } from '../service/TaskService'

class TaskFilter extends Component {
    constructor(props) {
        super()
        this.state = {
            businessType: "",
            businessTypes: [],
            status: "",
            statusGroup: {},
            startDate: "",
            endDate: "",
            customerName: "",
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleRestore = (event) => {
        event.preventDefault()
        this.setState((state) => {
            return {
                businessType: this.state.businessTypes.map(bt => bt.type),
                status: Object.values(this.state.statusGroup)[0].map(s => s.type),
                startDate: "",
                endDate: "",
                customerName: "",
            }
        }, () => {
            this.handleReloadTasks()
        })
    }

    handleSearch = (event) => {
        event.preventDefault()
        this.handleReloadTasks()
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.handleSearch(event)
        }
    }

    loadBusinessTypes = async (organizationCode) => {
        const response = await axios.get(`/organizations/${organizationCode}/business-types`)
        return response.data
    }

    loadTaskStatusGroup = async () => {
        const response = await axios.get(`/task/displayable-status-group`)
        return response.data
    }

    async componentDidUpdate(prevProps) {
        if (this.props.user.defaultOrganization.code !== prevProps.user.defaultOrganization.code) {
            const businessTypes = await this.loadBusinessTypes(this.props.user.defaultOrganization.code)
            this.setState({
                businessTypes: businessTypes,
                businessType: businessTypes.map(bt => bt.type),
            }, () => {
                this.handleReloadTasks()
            })
        }
    }

    async componentDidMount() {
        const businessTypes = await this.loadBusinessTypes(this.props.user.defaultOrganization.code)
        const taskStatusGroup = await this.loadTaskStatusGroup()
        this.setState({
            businessTypes: businessTypes,
            businessType: businessTypes.map(bt => bt.type),
            statusGroup: taskStatusGroup,
            status: Object.values(taskStatusGroup)[0].map(s => s.type),
        }, () => {
            this.handleReloadTasks()
        })
    }

    loadTasks = async () => {
        this.props.setTasks(await loadTasks(this.getFilters()))
        this.props.setSpinner(false)
    }

    handleReloadTasks = () => {
        this.props.setSpinner(true)
        this.props.setFilters(this.getFilters())
        this.loadTasks()
    }

    getFilters = () => {
        return {
            customerName: this.state.customerName,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            businessType: this.state.businessType,
            status: this.state.status,
            page: 0,
            pageSize: TASK_PAGE_SIZE,
            organizationCode: this.props.user.defaultOrganization.code
        }
    }

    render() {
        const { customerName, startDate, endDate, status, businessType, businessTypes, statusGroup } = this.state
        return (
            <Card className='biz__task-dashboard_filter-card'>
                <Form autoComplete="off" className="taskFilterForm">
                    <Card.Body className='biz__task-dashboard_filter-card_body'>
                        <Form.Group as={Row}>
                            <Col sm="3">
                                <Form.Control
                                    type="text"
                                    controlid="customerName"
                                    name="customerName"
                                    placeholder="Customer Name"
                                    value={customerName}
                                    onChange={this.handleChange.bind()}
                                    onKeyDown={this.handleKeyDown.bind()}
                                />
                            </Col>
                            <Col sm="3">
                                <Form.Control type="date"
                                    controlid="startDate"
                                    name="startDate"
                                    placeholder="From (Date Created)"
                                    value={startDate}
                                    onChange={this.handleChange.bind()}
                                    onKeyDown={this.handleKeyDown.bind()}
                                />
                            </Col>
                            <Col sm="3">
                                <Form.Control type="date"
                                    controlid="endDate"
                                    name="endDate"
                                    placeholder="To (Date Created)"
                                    value={endDate}
                                    onChange={this.handleChange.bind()}
                                    onKeyDown={this.handleKeyDown.bind()}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col sm="3">
                                <Form.Control as="select"
                                    size="sm"
                                    controlid="businessType"
                                    name="businessType"
                                    onChange={this.handleChange.bind()}
                                    onKeyDown={this.handleKeyDown.bind()}
                                    value={businessType}>

                                    <option value={businessTypes.length == 0 ? "" : businessTypes.map(businessType => businessType.type).reduce((prev, curr) => prev + "," + curr)}>  -- Select Business Type -- </option>
                                    {
                                        businessTypes.map((businessType, i) =>
                                            <option key={i} value={businessType.type}>{businessType.desc}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col sm="3">
                                <Form.Control as="select"
                                    size="sm"
                                    controlid="status"
                                    name="status"
                                    onChange={this.handleChange.bind()}
                                    onKeyDown={this.handleKeyDown.bind()}
                                    value={status}>

                                    {
                                        Object.keys(statusGroup).map((group, i) =>
                                            <option key={i} value={statusGroup[group].map(status => status.type).reduce((prev, curr) => prev + "," + curr)}>{group}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <div className="mt-3">
                            <Button variant="dark" className="float-left mr-3" size="sm" active onClick={this.handleSearch.bind()}>
                                <IconContext.Provider value={{ className: "icon-button" }}>
                                    <FaSearch />
                                </IconContext.Provider>
                                <span>Search</span>
                            </Button>

                            <Button variant="dark" className="float-left" size="sm" active onClick={this.handleRestore.bind()}>
                                <IconContext.Provider value={{ className: "icon-button" }}>
                                    <FaCircleNotch />
                                </IconContext.Provider>
                                <span>Restore</span>
                            </Button>
                        </div>
                    </Card.Body>
                </Form>
            </Card>

        )
    }

}

const mapStateToProps = state => ({
    user: state.UserReducer.user,
})
export default connect(mapStateToProps, { setUser, setTasks, setSpinner, setFilters })(TaskFilter)