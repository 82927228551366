import React, {Component} from "react"

import {Button} from "react-bootstrap"

export default class CustomerList extends Component {
    render() {
        return (
            <div>
                <Button href="/customer" variant="secondary" size="sm">Add Customer</Button>
            </div>
        )
    }
}
