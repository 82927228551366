import { createStore, applyMiddleware, compose } from 'redux'
import Thunk from 'redux-thunk'
import rootReducer from './reducers'

const initialState = {}

const middleware = [Thunk]

const middlewareElement = process.env.NODE_ENV === 'development' ? 
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
        :
        applyMiddleware(...middleware)

const store = createStore(
    rootReducer,
    initialState,
    middlewareElement
)
export default store
