import React from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { IconContext } from "react-icons"

function TextError (props) {
  return (
    <div className='error'>
        <IconContext.Provider value={{ style: { marginRight: "5px", color: "red" } }}>
            <BsInfoCircle />
        </IconContext.Provider>
        {props.children}
    </div>
  )
}

export default TextError