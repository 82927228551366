import React from 'react'
import ReactPaginate from 'react-paginate'
import { useSelector, useDispatch } from 'react-redux'
import { TASK_PAGE_SIZE } from '../../utils/Constant'
import { loadTasks } from '../service/TaskService'
import { setTasks } from '../../actions/TaskDashboardAction'

function TaskPagination() {
    const totalTasks = useSelector(state => state.TaskDashboardReducer.count)
    const filters = useSelector(state => state.TaskDashboardReducer.filters)
    const dispatch = useDispatch()
    
    const handlePaginationClick = async (e) => {
        const selectedPage = e.selected 
        const paginationfilters = {
            ...filters,
            page: selectedPage,
            pageSize: TASK_PAGE_SIZE,
        }
        const tasks = await loadTasks(paginationfilters)

        dispatch(setTasks(tasks))
    }
    return (
        <div className="commentBox">
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(totalTasks * 1.0 / TASK_PAGE_SIZE)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePaginationClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
            />
      </div>
    )
}

export default TaskPagination