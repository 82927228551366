import React from 'react'
import { RiEditBoxFill } from 'react-icons/ri'
import { Link } from "react-router-dom"

const ActionIcon = (className, isDisplay, fontSize, fontColor, link) => {
    return ( 
        <React.Fragment>
            {isDisplay &&
                <Link className={className} to={link}>
                    <RiEditBoxFill size={fontSize} color={fontColor} />
                </Link>
            }
        </React.Fragment>
        )
}

export default ActionIcon