import React, { Component } from 'react'
import { Card, Button, Col, Row, Container } from 'react-bootstrap'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import TextError from '../model/TextError'
import * as Yup from 'yup'
import GlobalErrorMessage from '../model/GlobalErrorMessage'
import { FaPlusSquare, FaEdit, FaBan } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { connect } from 'react-redux'
import './Customer.css'

class Customer extends Component {

    constructor(props) {
        super()
        this.state = {
            firstName: "",
            lastName: "",
            referralOnly: false, 
            isGlobalError: false,
            isCustomerExistError: false,
        }
    }

    formRef = React.createRef()
    async componentDidUpdate(prevProps) {
        if (this.props.user.defaultOrganization.code !== prevProps.user.defaultOrganization.code) {
            this.setState({
                firstName: "",
                lastName: "",
                referralOnly: false, 
                isGlobalError: false,
                isCustomerExistError: false,
            })  
            this.formRef.current.resetForm()
        }
    }

    render() {
        const {history} = this.props;
        const isAddAction = this.props.match.params.id === undefined;
        const initialValues = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            referralOnly: this.state.referralOnly,
        }

        const validationSchema = Yup.object({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
        })

        const onSubmit = async (values, {setSubmitting}) => {
            const customerName = (values.firstName + " " + values.lastName).trim()
            const customer = await axios.get(`/organizations/${this.props.user.defaultOrganization.code}/customer?name=${customerName}`)
            if (customer.data) {
                setSubmitting(false)
                this.setState({
                    isCustomerExistError: true,
                })
            } else {
                const data = {
                    name: customerName,
                    organization: {
                        code: this.props.user.defaultOrganization.code,
                    },
                    referralOnly: values.referralOnly
                }

                axios.post(`/customer`, data)
                    .then(() => {
                        history.push("/")
                    })
                    .catch(error => {
                        console.log(error)
                        setSubmitting(false)
                        this.setState({
                            isGlobalError: true,
                        })
                    })
                }
        }

        const formTitleText = isAddAction ? "Add Customer" : "Edit Customer"
        const submitButtonText = isAddAction ? "Add New" : "Update Customer"
        const submitButtonIcon = isAddAction ? <FaPlusSquare /> : <FaEdit />
        return (
            <Container>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    innerRef={this.formRef}
                >
                    {({ isSubmitting, values, setFieldValue }) => {
                        return (
                            <Form className="form">
                                <Card className="biz__customer-card card">
                                    <Card.Header><h3 className='biz__customer-card_header-label'>{formTitleText}</h3></Card.Header>
                                    <Card.Body>
                                    { this.state.isGlobalError && 
                                        <div className="form-control">    
                                            <Row className="text-centre">
                                                <Col>
                                                    <GlobalErrorMessage message="Oops, there was a problem submitting your request" />
                                                </Col>
                                            </Row>
                                        </div>
                                    }    

                                    { this.state.isCustomerExistError && 
                                        <div className="form-control">    
                                            <Row className="text-centre">
                                                <Col>
                                                    <GlobalErrorMessage message="Customer already exists" />
                                                </Col>
                                            </Row>
                                        </div>
                                    }                      

                                        <div className="form-control">
                                            <Row className="text-centre">
                                                <Col sm="4">
                                                    <label className="label" htmlFor="firstName">First Name<span className="red">*</span>:</label>
                                                </Col>
                                                <Col sm="5">
                                                    <Field as="input" type="text" id="firstName" name="firstName" />
                                                </Col>
                                                <>
                                                    <Col sm="3" className='biz__customer-input_error'>
                                                        <ErrorMessage name='firstName' component={TextError} />
                                                    </Col>
                                                    <Col className='biz__customer-input_error-sm'>
                                                        <ErrorMessage name='firstName' component={() => TextError({iconOnly: true})} />
                                                    </Col>
                                                </>
                                            </Row>
                                        </div>

                                        <div className="form-control">
                                            <Row className="text-centre">
                                                <Col sm="4">
                                                    <label className="label" htmlFor="lastName">Last Name<span className="red">*</span>:</label>
                                                </Col>
                                                <Col sm="5">
                                                    <Field as="input" type="text" id="lastName" name="lastName" />
                                                </Col>
                                                <>
                                                    <Col sm="3" className='biz__customer-input_error'>
                                                        <ErrorMessage name='lastName' component={TextError} />
                                                    </Col>
                                                    <Col className='biz__customer-input_error-sm'>
                                                        <ErrorMessage name='lastName' component={() => TextError({iconOnly: true})} />
                                                    </Col>
                                                </>
                                            </Row>
                                        </div>

                                        <div className="form-control">
                                            <Row className="text-centre">
                                                <Col sm="4">
                                                    <label className="label" htmlFor="type">Type<span className="red">*</span>:</label>
                                                </Col>
                                                <Col sm="5" className='biz__customer-referral_input-container'>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name="referralOnly"
                                                            value={true}
                                                            checked={values.referralOnly === true}
                                                            onChange={() => setFieldValue("referralOnly", true)}
                                                            />
                                                        <label className="ml-1">Referral Center</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name="referralOnly"
                                                            value={false}
                                                            checked={values.referralOnly === false}
                                                            onChange={() => setFieldValue("referralOnly", false)}
                                                            />
                                                        <label className="ml-1">Customer</label>
                                                    </div>
                                                </Col>
                                                <>
                                                    <Col sm="3" className='biz__customer-input_error' />
                                                    <Col className='biz__customer-input_error-sm' />
                                                </>
                                            </Row>
                                        </div>

                                    </Card.Body>
                                    <Card.Footer>
                                        <div className="form-control">
                                            <div className="text-left biz__customer-button_container">
                                                <Button variant="dark" type="submit" className="mr-3" size="sm" disabled={isSubmitting}>
                                                    <IconContext.Provider value={{ className: "icon-button"}}>
                                                        {submitButtonIcon}
                                                    </IconContext.Provider>
                                                    <span>{submitButtonText}</span>
                                                </Button>

                                                <Button variant="dark" onClick={() => history.push("/")} size="sm">
                                                    <IconContext.Provider value={{ className: "icon-button"}}>
                                                        <FaBan />
                                                    </IconContext.Provider>
                                                    <span>Cancel</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Form>
                        )
                    }}
                </Formik>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.user,
})

export default connect(mapStateToProps, null) (Customer)
