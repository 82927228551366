import React, { useState } from 'react'
import parser from "html-react-parser"
import { Modal, Container, Row, Col } from 'react-bootstrap'
import TaskDescriptionItem from './TaskDescriptionItem'
import ActionIcon from '../../utils/ActionIcon'

function MultipleTaskDescription(props) {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    // TODO: useCallback?
    const handleOnClickMore = (e) => {
        e.preventDefault()
        setModalIsOpen(true)
    }
    const moreStyle = {
        color: "red",
        margin: "0 10px",
        fontSize: "0.7rem"
    }

    const containerStyle = {
        fontSize: "0.8rem"
    }

    return (
        <td className='biz__task-dashboard_table-description'>
            <div className='biz__task-dashboard_multiple-description'>
                <div>
                    {parser(props.task.descriptions[0].desc)}
                    <a style={moreStyle} onClick={handleOnClickMore} href="#">[More...]</a>
                </div>
                {ActionIcon('biz__task-dashboard_table-description_icon', props.status !== "COMPLETE", '15', '#17a2b8', props.link)}  
            </div>
            
            <Modal
                show={modalIsOpen}
                onHide={() => setModalIsOpen(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-center">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='biz__task-dashboard_description-modal_body'>
                <Container className='biz__task-dashboard_description-modal_container' style={containerStyle}>
                    <Row className="justify-content-md-center">
                        <Col xs lg="3" className="font-weight-bold text-right">
                        Customer Name:
                        </Col>
                        <Col xs lg="3" className="text-left">
                        {props.task.customer.name}
                        </Col>
                        <Col xs lg="3" className="font-weight-bold text-right">
                        Business Type:
                        </Col>
                        <Col xs lg="3" className="text-left">
                        {props.task.businessType.desc}
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col xs lg="3" className="font-weight-bold text-right">
                        Assignee:
                        </Col>
                        <Col xs lg="3" className="text-left">
                        {props.task.assignee.name}
                        </Col>
                        <Col xs lg="3" className="font-weight-bold text-right">
                        Referral By:
                        </Col>
                        <Col xs lg="3" className="text-left">
                        {props.task.referral ? props.task.referral.name : ""}
                        </Col>
                    </Row>
                    {props.task.descriptions.map((description, i) => 
                        <TaskDescriptionItem key={i} description={description}/>
                    )}
                    </Container>
                </Modal.Body>
            </Modal>
        </td>
    )
}

export default MultipleTaskDescription