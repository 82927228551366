import { SET_TASKS, SET_SPINNER, SET_FILTERS } from './Types'

export const setTasks = taskPage => dispatch => {
    dispatch({
        type: SET_TASKS,
        payload: taskPage
    })
}

export const setSpinner = spinner => dispatch => {
    dispatch({
        type: SET_SPINNER,
        payload: spinner
    })
}

export const setFilters = filter => dispatch => {
    dispatch({
        type: SET_FILTERS,
        payload: filter
    })
}