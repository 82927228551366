import React, {Component} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import NavigationBar from './NavigationBar'
import Footer from './Footer'
import TaskList from './task/TaskList'
import Task from './task/Task'
import CustomerList from './customer/CustomerList'
import Customer from './customer/Customer'
import InvalidateCache from './cache/InvalidateCache'
import { HashRouter, BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from './login/Login'
import axios from 'axios'
import { valid } from './service/AuthService'
import { createBrowserHistory } from 'history'
import { setUser } from '../actions/UserAction'
import jsonwebtoken from 'jsonwebtoken'
import errorResponseHandler from './service/ErrorResponseHandler'

class MainContainer extends Component {
    constructor(props) {
        super()
    }

    render() {
        axios.defaults.baseURL = process.env.BIZTRACK_API_BASE_URL
        axios.defaults.headers.common['Content-Type'] = "application/json"
        axios.defaults.headers.common['Accept'] = "application/json"
        axios.interceptors.response.use(response => response, errorResponseHandler)
        const history = createBrowserHistory()
        const jwt = localStorage.getItem("token")

        let isUserLoggedin = false
        if (jwt) {
            if (valid(jwt)) {
                isUserLoggedin = true
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
                if (!this.props.user)
                    this.props.setUser(jsonwebtoken.decode(jwt))
                if (window.location.pathname.toLowerCase().includes("login"))
                    history.push("/")
            } else {
                this.props.setUser(null)
                localStorage.clear()
            }
        }
        return (
            <React.Fragment>
                { isUserLoggedin ?
                <HashRouter>
                    <NavigationBar />
                    <Container>
                        <Row>
                            <Col lg={12} className="main-container">
                                <Switch>
                                    <Route path="/" exact component={TaskList} />
                                    <Route path="/customers" component={CustomerList} />
                                    <Route path="/task/:id?" component={Task} />
                                    <Route path="/customer" component={Customer} />
                                    <Route path="/invalidate-cache" component={InvalidateCache} />
                                </Switch>
                            </Col>
                        </Row>
                    </Container>
                    <Footer />
                </HashRouter>
                :
                <Login />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.user,
})

export default connect(mapStateToProps, { setUser })(MainContainer)