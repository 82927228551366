import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import parser from "html-react-parser"

function TaskDescriptionItem(props) {
    return (
        <Container>
            <hr />
            <Row className="mb-3 mt-1">
                <Col className='large-screen' xs lg="1" />
                <Col xs lg="3" className="text-left">
                {props.description.writer.name}
                </Col>
                <Col className='large-screen' xs lg="4" />
                <Col xs lg="3" className="text-right">
                {props.description.creationDate}
                </Col>
                <Col className='biz__task-card_description-sm' xs lg="1" />
            </Row>
            <Row>
                <Col className='large-screen' xs lg="1">

                </Col>
                <Col xs lg="8" className="text-left ml-3">
                    {parser(props.description.desc)}
                </Col>
                <Col className='large-screen' xs lg="3">

                </Col>
            </Row>
        </Container>
    )
}

export default TaskDescriptionItem