import React from 'react'

import {Navbar, Nav, OverlayTrigger, NavDropdown, Col, Form} from 'react-bootstrap'
import { FaSignOutAlt } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import Img from '../images/brand-logo.png'
import { renderTooltip } from '../utils/Tooltip'
import { setUser } from '../actions/UserAction'
import UnreadCount from './UnreadCount'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

class NavigationBar extends React.Component {
    constructor(props) {
        super()
        this.state = {
            toggleMenu : false
        }
    }

    signout = () => {
        this.props.setUser(null)
        localStorage.clear()
    }

    handleSwitchOrganization = async (event) => {
        event.preventDefault()
        const organizationCode = event.target.value
        this.props.setUser(
            {
                ...this.props.user,
                defaultOrganization: this.props.user.organizations.find(organization => organization.code == organizationCode)
            }
        )
    }

    turnOnToggleMenu = () => {
        this.setState({
            toggleMenu : true
        })
    }

    turnOffToggleMenu = () => {
        this.setState({
            toggleMenu : false
        })
    }

    render() {
        const { organizations, defaultOrganization } = this.props.user
        const signoutComponent = (<>[<Link to={""} className="header-link" onClick={this.signout.bind()}>
        Log Out
            <IconContext.Provider value={{ style: { color: "white", marginLeft: "3px", marginRight: "3px"} }}>
                <OverlayTrigger
                    placement="bottom-start"
                    overlay={renderTooltip(`log-out-link`, "Log Out")}
                >
                <FaSignOutAlt />
                </OverlayTrigger>
            </IconContext.Provider>
        </Link>]</>)

        return (
            <Navbar bg="dark" variant="dark">
                <div className='biz__header-brand_container'>
                    <img src={Img} width="40" alt="brand logo"/>
                    <span className="text-white mr-5">BizTrack</span>
                </div>
                
                    
                <div className='biz__header-navigtation'>
                    <div className='biz__header-navigtation_container'>
                        <NavDropdown renderMenuOnMount={true} title="Task" id="nav-dropdown">
                            <NavDropdown.Item as={Link} to="/">Task Dashboard</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/task">Add Task</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown renderMenuOnMount={true} title="Customer" id="nav-dropdown" className="mr-auto">
                            <NavDropdown.Item as={Link} to="/customer">Add Customer</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <div className="header-right">
                        <div className='biz__header-welcome_label'>
                            <span>Welcome, </span>
                            <label>{this.props.user.name}</label>
                            (<UnreadCount />)
                        </div>
                        { organizations.length > 1 && 
                            <React.Fragment>
                                <label className='biz__header-from_label'>from</label> 
                                <Form> 
                                    <Form.Control as="select"  className='biz__header-select_company'
                                        size="sm"
                                        controlid="organizationCode"
                                        name="organizationCode"
                                        onChange={this.handleSwitchOrganization.bind()}
                                        value={defaultOrganization.code}>
                                        {
                                            organizations.map((organization, i) =>
                                                <option key={i} value={organization.code}>{organization.organizationName}</option>
                                            )
                                        }
                                    </Form.Control>
                                </Form> 
                            </React.Fragment>   
                        }
                        <span className="biz__header-button_signout header-span">
                            {signoutComponent}
                        </span>
                    </div>
                </div>
                <div className='biz__header-icon'>
                {!this.state.toggleMenu 
                    ? <RiMenu3Line size={27} color="#fff" onClick={this.turnOnToggleMenu.bind()} />
                    : <RiCloseLine size={27} color="#fff" onClick={this.turnOffToggleMenu.bind()} />
                }
                </div>
                {this.state.toggleMenu && (
                    <div className='biz__header-menu_link-container scale-up-center'>
                        <div className='biz__header-menu_links'>
                            <>
                                <p><a href="/">Task Dashboard</a></p>
                                <p><a href="/#/task">Add Task</a></p>
                                <p><a href="/#/customer">Add Customer</a></p>
                                <p>{signoutComponent}</p>
                            </>
                        </div>
                    </div>
                )}
            </Navbar>
        )
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.user,
})

export default connect(mapStateToProps, { setUser }) (NavigationBar)