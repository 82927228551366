import React, { Component } from 'react'
import { Card, Button, Col, Row, Container } from 'react-bootstrap'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import TextError from '../model/TextError'
import * as Yup from 'yup'
import Select from 'react-select'
import './css/Task.css'
import GlobalErrorMessage from '../model/GlobalErrorMessage'
import ReactQuill from 'react-quill'
import Quill from "quill"
import 'react-quill/dist/quill.snow.css'
import TaskDescriptionItem from './TaskDescriptionItem'
import { connect } from 'react-redux'
import { FaPlusSquare, FaEdit, FaBan } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'

var Block = Quill.import("blots/block")

Block.tagName = "DIV"
Quill.register(Block, true)

class Task extends Component {

    constructor(props) {
        super()
        this.state = {
            customers: [],
            businessTypes: [],
            businessType: "",
            assignees: [],
            assignee: "",
            isGlobalError: false,
            task: {},
            taskStatus: [],
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.props.user.defaultOrganization.code !== prevProps.user.defaultOrganization.code) {
            this.loadBusinessTypes()
            this.loadUsers()
            this.loadCustomers()
            this.onClearReactSelectInputs()  
        }
    }

    async componentDidMount() {
        this.loadBusinessTypes()
        this.loadUsers()
        this.loadCustomers()

        const isAddAction = this.props.match.params.id === undefined;
        if (!isAddAction) {
            const getTaskResponse = await axios.get(`/tasks/${this.props.match.params.id}`)
            let task = getTaskResponse.data
            if (!task.viewed && task.assignee.id === this.props.user.id) {
                task = {
                    ...task,
                    viewed: true
                } 
                const updateTaskResponse = await axios.post(`/task`, task)
                task = updateTaskResponse.data
            }
            this.setState({
                task: task,
            })

            axios.get(`/task/status`)
            .then(response => response.data)
            .then(data => {
                this.setState({
                    taskStatus: data,
                })
            })
        }
    }

    loadBusinessTypes = () => {
        axios.get(`/organizations/${this.props.user.defaultOrganization.code}/business-types`)
            .then(response => response.data)
            .then(data => {
                this.setState({
                    businessTypes: data,
                    businessType: data[0].type,
                })
            })
    }

    loadUsers = () => {
        axios.get(`/organizations/${this.props.user.defaultOrganization.code}/users`)
            .then(response => response.data)
            .then(data => {
                this.setState({
                    assignees: data,
                    assignee: data[0].id,
                })
            })
    }

    loadCustomers = () => {
        axios.get(`/organizations/${this.props.user.defaultOrganization.code}/customers`)
            .then(response => response.data)
            .then(data => {
                this.setState({
                    customers: data,
                })
            })
    }

    customerSelectInputRef = React.createRef()
    referralSelectInputRef = React.createRef()

    onClearReactSelectInputs() {
        this.customerSelectInputRef.current.select.clearValue()
        this.referralSelectInputRef.current.select.clearValue()
    }

    modules = {
        toolbar: [
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
        ],
      }
    
    formats = [
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet'
    ]

    render() {
        const {history} = this.props;
        const isAddAction = this.props.match.params.id === undefined;

        const filterOnStatus = (selectedStatus, status) => {
            let statusToDel = []
            if (this.props.user.role == "ROLE_USER")
                statusToDel = [ ...statusToDel, "COMPLETE", "DEL"]
            switch (selectedStatus) {
                case "NEW":
                    return ["IN_PRO", "PEND", "COMPLETE", "DEL"].filter(i => statusToDel.indexOf(i) === -1).indexOf(status.type) > -1
                case "IN_PRO":
                    return ["IN_PRO", "PEND", "COMPLETE", "DEL"].filter(i => statusToDel.indexOf(i) === -1).indexOf(status.type) > -1
                case "PEND":
                    return ["IN_PRO", "PEND", "COMPLETE", "DEL"].filter(i => statusToDel.indexOf(i) === -1).indexOf(status.type) > -1
                default:
                    return false
            }
        }

        const initialOnStatus = (status) => {
            switch (status) {
                case "NEW":
                    return "IN_PRO"
                default:
                    return status
            }
        }
        
        const initialValues = {
            customerId: isAddAction ? "" : this.state.task.customer?.id,
            businessType: isAddAction ? this.state.businessType : this.state.task.businessType?.type,
            assigneeId: isAddAction ? this.state.assignee : this.state.task.assignee?.id,
            referralId: isAddAction ? "" : this.state.task.referral?.id,
            taskStatus: isAddAction ? "NEW" : initialOnStatus(this.state.task.taskStatus?.type),
            description: "",
        }

        const validationSchema = Yup.object({
            customerId: Yup.string().required('Required'),
            businessType: Yup.string().required('Required'),
            assigneeId: Yup.string().required('Required'),
            taskStatus: Yup.string().required('Required'),
            description: Yup.string().when('taskStatus', {
                is: taskStatus => {
                    return ["COMPLETE", "DEL"].indexOf(taskStatus) === -1
                },
                then: Yup.string().required('Required')
            }),
        })

        const onSubmit = (values, {setSubmitting}) => {
            const data = {
                id: isAddAction ? null : this.props.match.params.id,
                customer: {
                    id: values.customerId,
                },
                businessType: {
                    type: values.businessType,
                },
                assignee: {
                    id: values.assigneeId,
                },
                organization: {
                    code: this.props.user.defaultOrganization.code,
                },
                taskStatus: {
                    type: values.taskStatus,
                },
                viewed: false,
                referral: values.referralId ? {id : values.referralId} : null,
                descriptions: values.description ? [
                    {
                        desc: values.description,
                        writer: {
                            id: this.props.user.id
                        },
                    },
                ]: null,
            }
                    
            axios.post(`/task`, data)
                .then(() => {
                    history.push("/")
                })
                .catch(error => {
                    console.log(error)
                    setSubmitting(false)
                    this.setState({
                        isGlobalError: true,
                    })
                })
        }

        const filterOption = (option, inputValue) => {
            if (inputValue === "")
                return true;
            let nameCheckArr = option.label.split(" ")
            nameCheckArr = [
                ...nameCheckArr,
                option.label,
            ]
            return nameCheckArr.filter(item => item.toLowerCase().startsWith(inputValue.toLowerCase())).length > 0
        }

        const referralOptions = this.state.customers.filter(customer => customer.name.toLowerCase() !== "admin").map(customer => ({label: customer.name, value: customer.id}))    
        const customerOptions = this.state.customers.filter(customer => !customer.referralOnly).map(customer => ({label: customer.name, value: customer.id}))    

        const formTitleText = isAddAction ? "Add Task" : "Edit Task"
        const submitButtonText = isAddAction ? "Add New" : "Update Task"
        const submitButtonIcon = isAddAction ? <FaPlusSquare /> : <FaEdit />
        return (
            <Container>
                <Formik
                    initialValues={initialValues}
                    validationSchema = {validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                >
                    {({ setFieldValue, setFieldTouched, isSubmitting, values }) => {
                        return (
                            <Form className="form">
                                <Card className="biz__task-card card">
                                    <Card.Header><h3 className='biz__task-card_header-label'>{formTitleText}</h3></Card.Header>
                                    <Card.Body>
                                    { this.state.isGlobalError && 
                                        <div className="form-control">    
                                            <Row className="text-centre">
                                                <Col>
                                                    <GlobalErrorMessage message="Oops, there was a problem submitting your request" />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                        
                                        <div className="form-control">
                                            <Row className="text-centre">
                                                <Col sm="4">
                                                    <label className="label" htmlFor="customerId">Customer Name<span className="red">*</span>:</label>
                                                </Col>
                                                <Col sm="5">
                                                    { isAddAction ?
                                                    <Select 
                                                        ref={this.customerSelectInputRef}
                                                        className="react-select biz__task-select-input"
                                                        id="customerId" 
                                                        name="customerId" 
                                                        value={customerOptions.find(option => option.value === values.customerId)}
                                                        options={customerOptions}
                                                        placeholder="Select..."
                                                        isClearable={true}
                                                        noOptionsMessage={() => "No options"}
                                                        filterOption={filterOption}
                                                        onChange={option => setFieldValue("customerId", option ? option.value : "")}
                                                        onBlur={() => setFieldTouched("customerId", true)} />
                                                    :
                                                    <label className="value" htmlFor="customerName">{this.state.task.customer?.name}</label>
                                                    }
                                                </Col>
                                                { isAddAction &&
                                                <>
                                                    <Col sm="3" className='biz__task-input_error'>
                                                        <ErrorMessage name='customerId' component={TextError} />
                                                    </Col>
                                                    <Col className='biz__task-input_error-sm'>
                                                        <ErrorMessage name='customerId' component={() => TextError({iconOnly: true})} />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </div>                            

                                        <div className="form-control">
                                            <Row className="text-centre">
                                                <Col sm="4">
                                                    <label className="label" htmlFor="businessType">Business Type<span className="red">*</span>:</label>
                                                </Col>
                                                <Col sm="5">
                                                    { isAddAction ?
                                                    <Field as="select" id="businessType" name="businessType" className="biz__task-select">
                                                        {this.state.businessTypes.map((businessType, i) => {
                                                            return (
                                                                <option key={i} value={businessType.type}>{businessType.desc}</option>
                                                            )
                                                        })}
                                                    </Field>
                                                    :
                                                    <label className="value" htmlFor="businessTypeValue">{this.state.task.businessType?.desc}</label>
                                                    }
                                                </Col>
                                                { isAddAction &&
                                                <>
                                                    <Col sm="3" className='biz__task-input_error'>
                                                        <ErrorMessage name='businessType' component={TextError} />
                                                    </Col>
                                                    <Col className='biz__task-input_error-sm'>
                                                        <ErrorMessage name='businessType' component={() => TextError({iconOnly: true})} />
                                                    </Col>
                                                </>
                                               }   
                                            </Row>
                                        </div>

                                        <div className="form-control">
                                            <Row className="text-centre">
                                                <Col sm="4">
                                                    <label className="label" htmlFor="assigneeId">Assignee<span className="red">*</span>:</label>
                                                </Col>
                                                <Col sm="5">
                                                    <Field as="select" id="assigneeId" name="assigneeId" className="biz__task-select">
                                                        {this.state.assignees.map((assignee, i) => {
                                                            return (
                                                                <option key={i} value={assignee.id}>{assignee.name}</option>
                                                            )
                                                        })}
                                                    </Field>
                                                </Col>
                                                { isAddAction &&
                                                <>
                                                    <Col sm="3" className='biz__task-input_error'>
                                                        <ErrorMessage name='assigneeId' component={TextError} />
                                                    </Col>
                                                    <Col className='biz__task-input_error-sm'>
                                                        <ErrorMessage name='assigneeId' component={() => TextError({iconOnly: true})} />
                                                    </Col>
                                                </>
                                               }   
                                            </Row>
                                        </div>

                                        <div className="form-control">
                                            <Row className="text-centre">
                                                <Col sm="4">
                                                    <label className="label" htmlFor="referralId">Referral By:</label>
                                                </Col>
                                                <Col sm="5">
                                                    { isAddAction ?
                                                    <Select 
                                                        ref={this.referralSelectInputRef}
                                                        className="react-select biz__task-select-input"
                                                        id="referralId" 
                                                        name="referralId" 
                                                        value={referralOptions.find(option => option.value === values.referralId)}
                                                        options={referralOptions}
                                                        placeholder="Select..."
                                                        isClearable={true}
                                                        noOptionsMessage={() => "No options"}
                                                        filterOption={filterOption}
                                                        onChange={option => setFieldValue("referralId", option ? option.value : "")}
                                                        onBlur={() => setFieldTouched("referralId", true)} />
                                                    :
                                                    <label className="value" htmlFor="referralValue">{this.state.task.referral?.name}</label>
                                                    }
                                                </Col>
                                                <Col className='biz__task-input_error-sm'></Col>
                                            </Row>
                                        </div>

                                        { isAddAction ?
                                        <Field type="hidden" id="taskStatus" name="taskStatus" />
                                        :
                                        <div className="form-control">
                                            <Row className="text-centre">
                                                <Col sm="4">
                                                    <label className="label" htmlFor="statusType">Status<span className="red biz__task-select">*</span>:</label>
                                                </Col>
                                                <Col sm="5">
                                                    <Field as="select" id="taskStatus" name="taskStatus">
                                                        {this.state.taskStatus.filter(status => filterOnStatus(this.state.task.taskStatus?.type, status)).map((status, i) => {
                                                            return (
                                                                <option key={i} value={status.type}>{status.description}</option>
                                                            )
                                                        })}
                                                    </Field>
                                                </Col>
                                                { isAddAction &&
                                                <Col sm="3">
                                                    <ErrorMessage name='taskStatus' component={TextError} />
                                                </Col>
                                                }
                                            </Row>
                                        </div>
                                        }

                                        <div className="form-control height-20">
                                            <Row>
                                                <Col sm="4" className="mt-1">
                                                    <label className="biz__task-card_description-lable label" htmlFor="description">Description
                                                    { ['COMPLETE', 'DEL'].indexOf(values.taskStatus) === -1 ? 
                                                    <span className="red">*</span>
                                                    :
                                                    ""
                                                    }
                                                    :</label>
                                                </Col>
                                                <Col sm="5">
                                                    <ReactQuill 
                                                        theme="snow" 
                                                        id="description"
                                                        name="description"
                                                        modules={this.modules}
                                                        formats={this.formats}
                                                        onChange={value => setFieldValue("description", value.trim().toLocaleLowerCase() === "<div><br></div>" ? "": value)}
                                                        onBlur={() => setFieldTouched("description", true)}
                                                    />
                                                </Col>
                                              
                                                <Col sm="3">
                                                    <ErrorMessage name='description' component={TextError} />
                                                </Col>
                                                
                                            </Row>
                                        </div>
                                        { !isAddAction && 
                                            <React.Fragment>
                                                <Field type="hidden" id="customerId" name="customerId" />
                                                <Field type="hidden" id="businessType" name="businessType" />
                                                <Field type="hidden" id="referralId" name="referralId" />
                                            </React.Fragment>
                                        }
                                    </Card.Body>
                                    <Card.Footer>
                                        <div className="form-control">
                                            <div className="text-left biz__task-button_container">
                                                <Button variant="dark" type="submit" className="mr-3" size="sm" disabled={isSubmitting}>
                                                    <IconContext.Provider value={{ className: "icon-button"}}>
                                                        {submitButtonIcon}
                                                    </IconContext.Provider>
                                                    <span>{submitButtonText}</span>
                                                </Button>

                                                <Button variant="dark" onClick={() => history.push("/")} size="sm">
                                                    <IconContext.Provider value={{ className: "icon-button"}}>
                                                        <FaBan />
                                                    </IconContext.Provider>
                                                    <span>Cancel</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Form>
                        )
                    }}
                </Formik>
                
                {!isAddAction && 
                    <Container className="descriptionContainer">
                        {this.state.task.descriptions?.map((description, i) => 
                        <TaskDescriptionItem key={i} description={description}/>)}
                    </Container>
                }
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.user,
})

export default connect(mapStateToProps, null) (Task)